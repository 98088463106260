<template>
  <div :class='pageClassByNavigationComponent'
    :style='minHeightStyle'>
    <component
      :is='pageNavigationComponent'
      :sidebar-links='sidebarConfigurations.sidebarLinks'
      :sidebar-page-title='sidebarConfigurations.sidebarPageTitle'
      :custom-background-stype='sidebarConfigurations.customBackgroundStyle' />
    <div class='px-4 lg:px-0 flex-grow relative'>
      <div class='py-8 lg:py-16'>
        <div class='flex flex-row gap-x-8 mb-4 lg:mb-8'>
          <h1 class='text-3xl font-bold'>Reset Password</h1>
        </div>
        <div class='mb-6 w-full lg:w-96'>
          <label 
            class='uppercase text-sm text-gray-700'>
            First Name
          </label>
          <input
            v-model='firstName'
            placeholder='First Name'
            class='border py-1 px-2 rounded block w-full'>
        </div>
        <div class='mb-6 w-full lg:w-96'>
          <label 
            class='uppercase text-sm text-gray-700'>
            Last Name
          </label>
          <input
            v-model='lastName'
            placeholder='Last Name'
            class='border py-1 px-2 rounded block w-full'>
        </div>
        <div class='mb-6 w-full lg:w-96'>
          <label 
            class='uppercase text-sm text-gray-700'>
            Phone Number
          </label>
          <div>
            <vue-phone-number-input
              class='block w-full'
              v-model='phoneNumber'
              :valid-color='eventMainThemeColor'
              default-country-code='KR'
              @update='updatePhoneNumberInput' />
          </div>
        </div>
        <div class='mb-6 w-full lg:w-96'>
          <label
            class='uppercase text-sm text-gray-700'>
            ID(E-mail)
          </label>
          <input
            v-model='email'
            placeholder='ID(E-mail)'
            class='border py-1 px-2 rounded block w-full'>
        </div>
        <div class='mb-6 w-full lg:w-96'>
          <label
            class='uppercase text-sm text-gray-700'>
            New Password
          </label>
          <input
            type='password'
            v-model='password'
            placeholder='New Password'
            class='border py-1 px-2 rounded block w-full'>
        </div>
        <div class='mb-6 w-full lg:w-96'>
          <label 
            class='uppercase text-sm text-gray-700'>
            Confirm New Password
          </label>
          <input
            type='password'
            v-model='passwordConfirmation'
            placeholder='Confirm New Password'
            class='border py-1 px-2 rounded block w-full'>
          <p class='mt-2 text-sm text-red-600' v-if='passwordConfirmation && password !== passwordConfirmation'>Please check your password confirmation.</p>
        </div>
        <button
          class='mb-6 w-full lg:w-96 border py-3 text-center text-white text-sm uppercase bg-gray-900 block rounded-md'
          :class='disabledChangeEventPasswordButtonClass'
          :disabled='disabledChangeEventPasswordButton'
          @click= 'changeEventLoginPassword'>
          Reset Password
        </button>
        <div class='bg-gray-100 p-8 font-semibold'>
          {{ changeLoginPasswordBoxText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'ChangeLoginPassword',
  components: {
    VuePhoneNumberInput,
    'tabs-page-navigation': () => import('@/components/common/TabsPageNavigation'),
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      phoneNumber: '',
      isValidPhoneNumber: false,
      changedPasswordUser: null,
    }
  },
  computed: {
    ...mapGetters('events',[
      'eventMainThemeColor',
    ]),
    pageClassByNavigationComponent () {
      return this.pageNavigationComponent === 'sidebar-page-navigation' ? 'flex flex-col lg:flex-row justify-between items-stretch gap-x-16' : ''
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    pageNavigationComponent () {
      return 'tabs-page-navigation'
    },
    disabledChangeEventPasswordButton () {
      return !this.firstName ||
             !this.lastName ||
             !this.isValidPhoneNumber ||
             !this.email ||
             !this.password ||
             !this.passwordConfirmation ||
             this.password !== this.passwordConfirmation
             
    },
    disabledChangeEventPasswordButtonClass () {
      return this.disabledChangeEventPasswordButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    changeEventLoginPasswordParams () {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        email: this.email,
        password: this.password,
      }
    },
    sidebarConfigurations () {
      return {
        sidebarLinks: [
          {
            'link': 'Login',
            'title': 'Go To Login',
            'actionType': 'open_internal_route'
          },
          {
            'link': 'FindLoginId',
            'title': 'Find Id(E-mail)',
            'actionType': 'open_internal_route'
          },
          {
            'link': 'ChangeLoginPassword',
            'title': 'Reset Password',
            'actionType': 'open_internal_route'
          },
        ],
        sidebarPageTitle: '',
        customBackgroundStyle: ''
      }
    },
    changeLoginPasswordBoxText () {
      if (this.changedPasswordUser) {
        return 'Password reset completed.'
      } else {
        return 'Please input name, phone number, ID(E-mail), and new password to reset the password.'
      }
    },
  },
  methods: {
    ...mapActions('users', [
      'updateEventLoginPassword'
    ]),
    updatePhoneNumberInput (data) {
      this.phoneNumber = data.formatInternational
      this.isValidPhoneNumber = data.isValid
    },
    changeEventLoginPassword () {
      this.updateEventLoginPassword(this.changeEventLoginPasswordParams).then(resp => {
        this.$alert('Password reset completed.', {
          confirmButtonText: 'OK',
          type: 'success'
        })
        this.changedPasswordUser = resp
      }).catch((error) => {
        if (error.response.data.error === 'NoUserError') {
          this.$alert("Can't find the user information. Please try with different information.", {
            confirmButtonText: 'OK',
            type: 'warning'
          })
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold;
}

@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}    
</style>
